//screen break-points variables
$tab: 580px;
$desk: 1040px;
$large: 1600px;
$mobile-height: calc(100vh - 75px);
$desk-height: 100vh;
//end screen break-point variables

@mixin tab {
	@media (min-width: #{$tab}) {
		@content;
	}
}
@mixin desk {
	@media (min-width: #{$desk}) {
		@content;
	}
}
@mixin large {
	@media (min-width: #{$large}) {
		@content;
	}
}

@mixin flex {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
