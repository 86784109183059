.next {
  position: fixed;
  top: 47%;
  right: 0.5rem;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  z-index: 9;
  transition: all 100ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.next:hover {
  cursor: pointer;
  background-color: rgb(220, 220, 220);
}
.next:active {
  transform: scale(0.9);
  background-color: rgb(174, 174, 174);
}
@media (min-width: 1040px) {
  .next {
    right: 2rem;
  }
}
@media (min-width: 1600px) {
  .next {
    right: 5rem;
  }
}
.next i {
  color: black;
  font-size: 2rem;
}

.prev {
  top: 47%;
  left: 0.5rem;
}
@media (min-width: 1040px) {
  .prev {
    left: 2rem;
  }
}
@media (min-width: 1600px) {
  .prev {
    left: 5rem;
  }
}

.lead {
  text-align: center;
  width: 100%;
  padding-top: 2rem;
}

.App {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  overflow: scroll;
}

.section {
  width: 100vw !important;
  height: calc(100vh - 130px);
  overflow-y: hidden;
}
@media (min-width: 1040px) {
  .section {
    height: 100vh;
  }
}

.btn-primary {
  text-decoration: none;
  padding: 8px 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: white;
  background-color: rgb(65, 65, 65);
  font-size: 0.8rem;
  font-weight: bold;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.235);
  transition: all 100ms ease-in-out;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.btn-primary:hover {
  cursor: pointer;
  filter: brightness(0.9);
}
.btn-primary:active {
  cursor: pointer;
  filter: brightness(0.8);
  transform: scale(0.99);
}
.btn-primary:disabled {
  opacity: 0.6;
  transform: none;
  filter: brightness(1);
}
@media (min-width: 1040px) {
  .btn-primary {
    padding: 10px 50px;
  }
}

.project-card {
  height: auto;
  width: 100%;
  background-color: rgba(45, 45, 45, 0.681);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.project-card h4 {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
}
@media (min-width: 580px) {
  .project-card h4 {
    font-size: 1rem;
  }
}
.project-card p {
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  opacity: 0.8;
}
@media (min-width: 580px) {
  .project-card p {
    font-size: 1rem;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: "Signika Negative", sans-serif;
  scroll-behavior: smooth;
  -webkit-backface-visibility: hidden;
  color: white;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overflow-y: hidden;
}

.rain {
  position: fixed;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-animation-name: bgchange;
          animation-name: bgchange;
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  z-index: -1;
}
.rain i {
  position: absolute;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgb(255, 255, 255) 100%);
  -webkit-animation: drop infinite linear;
          animation: drop infinite linear;
  border-radius: 25% 25% 10% 10%;
  opacity: 0.7;
  z-index: 1;
}

@-webkit-keyframes drop {
  0% {
    top: 0;
  }
  100% {
    top: 100vh;
  }
}

@keyframes drop {
  0% {
    top: 0;
  }
  100% {
    top: 100vh;
  }
}
@-webkit-keyframes bgchange {
  0% {
    background-color: rgb(0, 0, 0);
  }
  50% {
    background-color: rgba(62, 62, 62, 0.612);
  }
  70% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: rgba(62, 62, 62, 0.612);
  }
}
@keyframes bgchange {
  0% {
    background-color: rgb(0, 0, 0);
  }
  50% {
    background-color: rgba(62, 62, 62, 0.612);
  }
  70% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: rgba(62, 62, 62, 0.612);
  }
}
.landing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.landing .landing-content {
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1040px) {
  .landing .landing-content {
    flex-direction: row;
  }
}
.landing .landing-content img {
  height: 250px;
  z-index: -2;
}
@media (min-width: 580px) {
  .landing .landing-content img {
    height: 350px;
  }
}
@media (min-width: 1040px) {
  .landing .landing-content img {
    height: 400px;
    order: 2;
  }
}
@media (min-width: 1600px) {
  .landing .landing-content img {
    height: 500px;
    order: 2;
  }
}
.landing .landing-content .text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  width: 90%;
  text-align: center;
}
@media (min-width: 580px) {
  .landing .landing-content .text {
    width: 85%;
    max-width: 750px;
  }
}
@media (min-width: 1040px) {
  .landing .landing-content .text {
    width: 50%;
    max-width: 750px;
    margin-top: 150px;
    order: 1;
  }
}
@media (min-width: 1600px) {
  .landing .landing-content .text {
    width: 70%;
    max-width: 850px;
  }
}
.landing .landing-content .text h1 {
  font-size: 2.5rem;
  margin: 0.1rem;
  padding: 0;
  text-shadow: 2px 2px 0px white;
  border: 1px solid white;
  padding: 0 1rem;
  margin-top: 0.5rem;
}
@media (min-width: 580px) {
  .landing .landing-content .text h1 {
    margin: 0.7rem 0;
    font-size: 3rem;
    padding: 0 3rem;
  }
}
@media (min-width: 1040px) {
  .landing .landing-content .text h1 {
    text-align: left;
    align-self: flex-start;
  }
}
@media (min-width: 1600px) {
  .landing .landing-content .text h1 {
    font-size: 3.5rem;
  }
}
.landing .landing-content .text p {
  margin: 0.1rem;
  padding: 0;
  font-size: 1.2rem;
}
@media (min-width: 580px) {
  .landing .landing-content .text p {
    font-size: 1.5rem;
    text-align: left;
  }
}
@media (min-width: 1600px) {
  .landing .landing-content .text p {
    font-size: 2rem;
  }
}
.landing .landing-content .text h2 {
  margin-bottom: 2px;
}
@media (min-width: 580px) {
  .landing .landing-content .text h2 {
    font-size: 1.5rem;
    text-align: left;
  }
}
.landing .landing-content .text .rule {
  width: 100%;
  background: -webkit-linear-gradient(left, #005eff, #e60ae6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.landing .landing-content .post {
  position: fixed;
  left: -50px;
  top: 20px;
  opacity: 0.4;
  -webkit-animation: animate_post 1s linear infinite;
          animation: animate_post 1s linear infinite;
  z-index: -1;
}
@media (min-width: 1040px) {
  .landing .landing-content .post {
    left: -50px;
    top: 100px;
  }
}
@media (min-width: 1600px) {
  .landing .landing-content .post {
    left: 50px;
    top: 100px;
  }
}
.landing .landing-content .post img {
  height: 500px;
}
@media (min-width: 1040px) {
  .landing .landing-content .post img {
    height: 1000px;
  }
}
@-webkit-keyframes animate_post {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes animate_post {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}

.skills .content-skills {
  height: 80vh;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-self: center;
  padding-bottom: 20px;
}
.skills .content-skills .row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
}
@media (min-width: 580px) {
  .skills .content-skills .row {
    grid-template-rows: repeat(4, 1fr);
  }
}
@media (min-width: 1040px) {
  .skills .content-skills .row {
    grid-template-rows: repeat(4, 1fr);
  }
}
.skills .skill {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 50px 0 #181818;
  position: relative;
  transform: rotate(-30deg) skew(20deg) scale(0.8);
  box-shadow: 0 0 50px 0 #181818;
  margin: auto;
  padding: 0;
  margin: auto;
  -webkit-animation: animate 3s linear infinite;
          animation: animate 3s linear infinite;
}
.skills .skill:nth-child(3) {
  -webkit-animation: animate1 3s linear infinite;
          animation: animate1 3s linear infinite;
}
.skills .skill:nth-child(5) {
  -webkit-animation: animate2 3s linear infinite;
          animation: animate2 3s linear infinite;
}
.skills .skill i {
  font-size: 2.5rem;
}
@media (min-width: 1040px) {
  .skills .skill {
    height: 150px;
    width: 150px;
  }
  .skills .skill i {
    font-size: 3.5rem;
  }
  .skills .skill small {
    font-size: 1.5rem !important;
  }
}
@-webkit-keyframes animate {
  0% {
    position: relative;
    left: 0;
  }
  50% {
    position: relative;
    left: 10px;
  }
  100% {
    position: relative;
    left: 0px;
  }
}
@keyframes animate {
  0% {
    position: relative;
    left: 0;
  }
  50% {
    position: relative;
    left: 10px;
  }
  100% {
    position: relative;
    left: 0px;
  }
}
@-webkit-keyframes animate1 {
  0% {
    position: relative;
    left: 0;
  }
  50% {
    position: relative;
    left: 15px;
  }
  100% {
    position: relative;
    left: 0px;
  }
}
@keyframes animate1 {
  0% {
    position: relative;
    left: 0;
  }
  50% {
    position: relative;
    left: 15px;
  }
  100% {
    position: relative;
    left: 0px;
  }
}
@-webkit-keyframes animate2 {
  0% {
    position: relative;
    right: 0;
  }
  50% {
    position: relative;
    right: 5px;
  }
  100% {
    position: relative;
    right: 0px;
  }
}
@keyframes animate2 {
  0% {
    position: relative;
    right: 0;
  }
  50% {
    position: relative;
    right: 5px;
  }
  100% {
    position: relative;
    right: 0px;
  }
}

.projects {
  height: 100%;
  width: 100%;
}
.projects .project-cards {
  height: 70vh;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  margin: auto;
  gap: 1rem;
}
@media (min-width: 1040px) {
  .projects .project-cards {
    width: 60%;
    height: 85vh;
    gap: 2rem;
  }
}

.contact {
  height: calc(100vh - (100vh - 100%));
  width: 100vw;
  position: relative;
  margin: 0;
  padding: 0;
}
.contact .lead {
  padding: 0;
  padding-top: 1rem;
}
@media (min-width: 1040px) {
  .contact .lead {
    padding-top: 2.5rem;
  }
}
@media (min-width: 1040px) {
  .contact {
    height: 100vh;
    padding: 0;
    margin-top: -20px;
  }
}

form {
  padding: 0;
  margin: 0;
}
form p {
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
}

.content-contact {
  padding: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 580px) {
  .content-contact {
    margin-top: 0px;
    width: 100%;
  }
}
@media (min-width: 1040px) {
  .content-contact {
    height: 70vh;
  }
}
.content-contact .input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85vw;
  max-width: 600px !important;
  margin-bottom: 0.5rem;
}
@media (min-width: 1040px) {
  .content-contact .input {
    width: 70vw;
    margin-bottom: 1.5rem;
  }
}
.content-contact .input label {
  align-self: flex-start;
}
.content-contact .input input {
  border-left: none;
  border-top: none;
  border-right: none;
  background-color: transparent;
  color: white;
  padding: 0.5rem 1rem;
  width: calc(100% - 2rem);
  font-size: 1rem;
}
@media (min-width: 1040px) {
  .content-contact .input input {
    padding: 1rem;
  }
}
.content-contact .input .borderless-textarea {
  color: white;
  background-color: transparent;
  width: calc(100% - 0.5rem);
  margin-top: 1rem;
}
.content-contact .btn-submit {
  width: 100%;
  padding: 1rem;
  background-color: white;
  color: black;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 130px;
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
}
@media (min-width: 1040px) {
  .socials {
    bottom: 20px;
  }
}
.socials .social-icons {
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-direction: row;
  width: 200px;
  justify-content: space-around;
  margin-left: -32px;
  list-style: none;
}
.socials .social-icons li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.socials .social-icons li a {
  text-decoration: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(60, 60, 60, 0.48);
  margin-bottom: 0.5rem;
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.socials .social-icons li a:hover {
  cursor: pointer;
  background-color: white;
}
.socials .social-icons li a:hover i {
  transform: scale(1.1);
  color: black;
}
.socials .social-icons li a i {
  color: white;
  font-size: 1.5rem;
  transition: all 300ms ease-in-out;
  transition-delay: 100ms;
}/*# sourceMappingURL=index.css.map */