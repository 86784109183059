@import "./1helper";
@import "./2classes";
body {
	margin: 0;
	padding: 0;
	background-color: black;
	font-family: "Signika Negative", sans-serif;
	scroll-behavior: smooth;
	-webkit-backface-visibility: hidden;
	color: white;
	-webkit-font-smoothing: antialiased;
	height: 100%;
	overflow-y: hidden;
}
.rain {
	position: fixed;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: white;
	height: 100%;
	width: 100%;
	overflow: hidden;
	animation-name: bgchange;
	animation-duration: 200ms;
	animation-iteration-count: 1;
	z-index: -1;
	i {
		position: absolute;

		background: rgb(255, 255, 255);
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.16) 0%,
			rgba(255, 255, 255, 1) 100%
		);
		animation: drop infinite linear;
		border-radius: 25% 25% 10% 10%;
		opacity: 0.7;
		z-index: 1;
	}
}

@keyframes drop {
	0% {
		top: 0;
	}
	100% {
		top: 100vh;
	}
}
@keyframes bgchange {
	0% {
		background-color: rgb(0, 0, 0);
	}
	50% {
		background-color: rgba(62, 62, 62, 0.612);
	}
	70% {
		background-color: rgb(0, 0, 0);
	}
	100% {
		background-color: rgba(62, 62, 62, 0.612);
	}
}

.landing {
	@include flex;
	color: white;

	.landing-content {
		margin-top: -100px;
		@include flex;
		@include desk {
			flex-direction: row;
		}
		img {
			height: 250px;
			z-index: -2;
			@include tab {
				height: 350px;
			}
			@include desk {
				height: 400px;
				order: 2;
			}
			@include large {
				height: 500px;
				order: 2;
			}
		}
		.text {
			@include flex;
			z-index: 2;
			width: 90%;
			text-align: center;
			@include tab {
				width: 85%;
				max-width: 750px;
			}
			@include desk {
				width: 50%;
				max-width: 750px;
				margin-top: 150px;
				order: 1;
			}
			@include large {
				width: 70%;
				max-width: 850px;
			}
			h1 {
				font-size: 2.5rem;
				margin: 0.1rem;
				padding: 0;

				text-shadow: 2px 2px 0px white;

				border: 1px solid white;
				padding: 0 1rem;
				margin-top: 0.5rem;
				@include tab {
					margin: 0.7rem 0;
					font-size: 3rem;
					padding: 0 3rem;
				}
				@include desk {
					text-align: left;
					align-self: flex-start;
				}
				@include large {
					font-size: 3.5rem;
				}
			}

			p {
				margin: 0.1rem;
				padding: 0;
				font-size: 1.2rem;

				@include tab {
					font-size: 1.5rem;
					text-align: left;
				}
				@include large {
					font-size: 2rem;
				}
			}
			h2 {
				margin-bottom: 2px;
				@include tab {
					font-size: 1.5rem;
					text-align: left;
				}
			}
			.rule {
				width: 100%;

				background: -webkit-linear-gradient(left, #005eff, #e60ae6);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
		.post {
			position: fixed;
			left: -50px;
			top: 20px;
			opacity: 0.4;
			animation: animate_post 1s linear infinite;
			z-index: -1;
			@include desk {
				left: -50px;
				top: 100px;
			}
			@include large {
				left: 50px;
				top: 100px;
			}
			img {
				height: 500px;
				@include desk {
					height: 1000px;
				}
			}
			@keyframes animate_post {
				0% {
					opacity: 0.2;
				}
				50% {
					opacity: 0.5;
				}
				100% {
					opacity: 0.2;
				}
			}
		}
	}
}

.skills {
	// overflow: hidden;
	.content-skills {
		height: 80vh;
		width: 90%;
		margin: auto;
		display: flex;
		justify-content: center;
		align-self: center;
		padding-bottom: 20px;
		.row {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: repeat(5, 1fr);
			@include tab {
				grid-template-rows: repeat(4, 1fr);
			}
			@include desk {
				grid-template-rows: repeat(4, 1fr);
			}
		}
	}

	.skill {
		height: 100px;
		width: 100px;
		display: flex;
		flex-direction: column;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		position: relative;

		box-shadow: 0 0 50px 0 #181818;
		position: relative;
		transform: rotate(-30deg) skew(20deg) scale(0.8);
		box-shadow: 0 0 50px 0 #181818;
		margin: auto;
		padding: 0;
		margin: auto;
		animation: animate 3s linear infinite;

		&:nth-child(3) {
			animation: animate1 3s linear infinite;
		}
		&:nth-child(5) {
			animation: animate2 3s linear infinite;
		}

		i {
			font-size: 2.5rem;
		}
		@include desk {
			height: 150px;
			width: 150px;
			i {
				font-size: 3.5rem;
			}
			small {
				font-size: 1.5rem !important;
			}
		}
		@keyframes animate {
			0% {
				position: relative;
				left: 0;
			}
			50% {
				position: relative;
				left: 10px;
			}
			100% {
				position: relative;
				left: 0px;
			}
		}
		@keyframes animate1 {
			0% {
				position: relative;
				left: 0;
			}
			50% {
				position: relative;
				left: 15px;
			}
			100% {
				position: relative;
				left: 0px;
			}
		}
		@keyframes animate2 {
			0% {
				position: relative;
				right: 0;
			}
			50% {
				position: relative;
				right: 5px;
			}
			100% {
				position: relative;
				right: 0px;
			}
		}
	}
}

.projects {
	height: 100%;
	width: 100%;

	.project-cards {
		height: 70vh;
		width: 80%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(4, auto);

		margin: auto;
		gap: 1rem;
		@include desk {
			width: 60%;
			height: 85vh;
			gap: 2rem;
		}
	}
}
.contact {
	height: calc(100vh - calc(100vh - 100%));
	width: 100vw;
	position: relative;

	margin: 0;
	padding: 0;
	.lead {
		padding: 0;
		padding-top: 1rem;
		@include desk {
			padding-top: 2.5rem;
		}
	}
	@include desk {
		height: 100vh;
		padding: 0;
		margin-top: -20px;
	}
}
// .form-wrapper {
// 	@include flex;
// 	height: calc(100vh - 150px);
// 	width: 100%;
// 	background-color: yellow;
// 	@include tab {
// 		height: 70vh;
// 	}
// }
form {
	padding: 0;
	margin: 0;

	p {
		height: 0;
		width: 0;
		padding: 0;
		margin: 0;
	}
}
.content-contact {
	padding: 20px;
	margin: auto;
	@include flex;
	width: 80%;
	height: 55vh;
	@include flex;

	@include tab {
		margin-top: 0px;
		width: 100%;
	}
	@include desk {
		height: 70vh;
	}
	// margin-top: -50px;

	.input {
		@include flex;
		width: 85vw;
		max-width: 600px !important;
		margin-bottom: 0.5rem;
		@include desk {
			width: 70vw;
			margin-bottom: 1.5rem;
		}
		label {
			align-self: flex-start;
		}
		input {
			border-left: none;
			border-top: none;
			border-right: none;
			background-color: transparent;
			color: white;
			padding: 0.5rem 1rem;
			width: calc(100% - 2rem);
			font-size: 1rem;
			@include desk {
				padding: 1rem;
			}
		}
		.borderless-textarea {
			color: white;
			background-color: transparent;
			width: calc(100% - 0.5rem);
			margin-top: 1rem;
		}
	}
	.btn-submit {
		width: 100%;
		padding: 1rem;
		background-color: white;
		color: black;
	}
}

.socials {
	@include flex;
	width: 100%;

	height: 130px;
	position: absolute;
	bottom: 0;
	margin: 0;
	left: 0;
	@include desk {
		bottom: 20px;
	}

	.social-icons {
		margin-top: 0;
		@include flex;
		flex-direction: row;
		width: 200px;
		justify-content: space-around;
		margin-left: -32px;
		list-style: none;

		li {
			@include flex;

			a {
				text-decoration: none;
				height: 50px;
				width: 50px;
				border-radius: 50%;
				background-color: rgba(60, 60, 60, 0.48);
				margin-bottom: 0.5rem;
				transition: all 300ms ease-in-out;
				@include flex;
				&:hover {
					cursor: pointer;
					background-color: white;
					i {
						transform: scale(1.1);
						color: black;
					}
				}
				i {
					color: white;
					font-size: 1.5rem;
					transition: all 300ms ease-in-out;
					transition-delay: 100ms;
				}
			}
		}
	}
}
