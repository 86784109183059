.next {
	position: fixed;
	top: 47%;
	right: 0.5rem;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background-color: white;
	z-index: 9;
	transition: all 100ms ease-in-out;
	&:hover {
		cursor: pointer;
		background-color: rgb(220, 220, 220);
	}
	&:active {
		transform: scale(0.9);
		background-color: rgb(174, 174, 174);
	}
	@include flex;
	@include desk {
		right: 2rem;
	}
	@include large {
		right: 5rem;
	}
	i {
		color: black;
		font-size: 2rem;
	}
}
.prev {
	top: 47%;
	left: 0.5rem;
	@include desk {
		left: 2rem;
	}
	@include large {
		left: 5rem;
	}
}
.lead {
	text-align: center;
	width: 100%;
	padding-top: 2rem;
}

.App {
	width: fit-content;
	display: flex;
	overflow: scroll;
}
.section {
	width: 100vw !important;
	height: calc(100vh - 130px);

	overflow-y: hidden;
	@include desk {
		height: 100vh;
	}
}
.btn-primary {
	text-decoration: none;
	padding: 8px 40px;
	border-radius: 5px;
	border: none;
	outline: none;
	color: white;
	background-color: rgb(65, 65, 65);
	font-size: 0.8rem;
	font-weight: bold;

	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.235);
	transition: all 100ms ease-in-out;
	backdrop-filter: blur(2px);

	&:hover {
		cursor: pointer;
		filter: brightness(0.9);
	}
	&:active {
		cursor: pointer;
		filter: brightness(0.8);
		transform: scale(0.99);
	}
	&:disabled {
		opacity: 0.6;
		transform: none;
		filter: brightness(1);
	}
	@include desk {
		padding: 10px 50px;
	}
}

.project-card {
	height: auto;
	width: 100%;

	background-color: rgba(45, 45, 45, 0.681);
	border-radius: 10px;
	@include flex;
	gap: 5px;

	h4 {
		font-size: 0.9rem;
		margin: 0;
		padding: 0;
		@include tab {
			font-size: 1rem;
		}
	}
	p {
		font-size: 0.8rem;
		margin: 0;
		padding: 0;
		opacity: 0.8;
		@include tab {
			font-size: 1rem;
		}
	}
}
